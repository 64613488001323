<template>
  <div class="container-right">
    <div class="container-right-name">
          会员权益明细
          <div class="line"></div>
      </div>
      <el-table
          :data="vipConfigList"
          style="width: 100%">
          <el-table-column
            prop="n"
            label="功能"
            width="180">
          </el-table-column>
          <el-table-column
            prop="t"
            label="会员次数">
          </el-table-column>
          <el-table-column
            prop="r"
            label="已使用次数"
            width="180">
          </el-table-column>
          <el-table-column
            prop="u"
            label="剩余可用次数"
            width="180">
          </el-table-column>
      </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'Vip',
    computed: {
    ...mapGetters(["mid", "userInfo", "vipConfig","vipInfo"]),
          vipConfigList: function () {
      let arr = [];
      let vipInfo=this.vipInfo;
      if(vipInfo){
      }else{
      
         vipInfo={
          existCount:0,
          registerCount:0,
          detectCount:0,
          obtainCount:0,
           existTotalCount:0,
          registerTotalCount:0,
          detectTotalCount:0,
          obtainTotalCount:0
        };
      }

      arr.push({
        n: "版权存证",
        t: vipInfo.existTotalCount,
        u: vipInfo.existCount,
        r: vipInfo.existTotalCount-vipInfo.existCount,
      });
      arr.push({
        n: "版权登记",
        t: vipInfo.registerTotalCount,
        u: vipInfo.registerCount,
        r: vipInfo.registerTotalCount-vipInfo.registerCount,
      });
      arr.push({
        n: "实时监测",
        t: vipInfo.detectTotalCount,
        u: vipInfo.detectCount,
        r: vipInfo.detectTotalCount-vipInfo.detectCount,
      });

      arr.push({
        n: "版权取证",
        t: vipInfo.obtainTotalCount,
        u: vipInfo.obtainCount,
        r: vipInfo.obtainTotalCount-vipInfo.obtainCount,
      });

      return arr;
    },
    },

  data () {
    return {
      tableData: [{
            function: '版权存证',
            Totaltimes: '100',
            Usedtimes: '12',
            Remainingtimes: '88'
          }, {
            function: '版权登记',
            Totaltimes: '100',
            Usedtimes: '12',
            Remainingtimes: '88'
          }, {
            function: '版权监测',
            Totaltimes: '100',
            Usedtimes: '12',
            Remainingtimes: '88'
          }, {
            function: '版权取证',
            Totaltimes: '100',
            Usedtimes: '12',
            Remainingtimes: '88'
          }]
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
@import '../../../assets/css/mine.scss';
</style>
